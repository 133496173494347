import { Link } from 'gatsby';
import * as React from 'react';
import LayoutTwo from '../components/layoutTwo';

// markup
const NotFoundPage = () => {
    return (
        <LayoutTwo>
            <main className="site-wrap">
                <div className="redirect-container">
                    <h1 className="font__large">
                        Oopse looks like this page does not exist. Click{' '}
                        <Link to="/" className="font__large backHome">
                            Here
                        </Link>{' '}
                        to go back.
                    </h1>
                </div>
            </main>
        </LayoutTwo>
    );
};

export default NotFoundPage;
